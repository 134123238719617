import {
  globalGetService,
  globalPutService,
} from '../../../utils/globalApiServices';
import { convertTimeWithTimezone, US_DATE_FORMAT } from '../../../utils/time';
import {
  AdditionalFilters,
  Pagination,
  SelectItem,
  UsecaseType,
} from '../../../utils/types';
import { CampaignsData } from '../Campaigns/types';
import {
  Brand,
  CspSuggestion,
  SuspensionCategory,
  SuspensionRule,
  VettingRecord,
} from './types';

export const fetchCspSuggestions = async (queries = {}) => {
  const response = await globalGetService('mno/csp/suggestions', {
    ...queries,
    limit: 20,
  });
  if (response.ok) {
    return response.data as CspSuggestion[];
  }
  return [] as CspSuggestion[];
};

export const fetchBrands = async (
  queries = {}
): Promise<Pagination<Brand> | undefined> => {
  const response = await globalGetService('mno/brands', queries);
  if (response?.ok) {
    return response.data as Pagination<Brand>;
  }
  return undefined;
};

export const getAllUsecasesTypes = async (): Promise<UsecaseType[]> => {
  const response = await globalGetService('enums/usecaseTypes');
  if (response?.ok) {
    return response.data;
  }
  return [];
};

export const getBrandDetail = async (
  brandUid: string
): Promise<Brand | undefined> => {
  const response = await globalGetService(`mno/brands/${brandUid}`);
  return response?.ok ? response.data : undefined;
};

export const getVettingRecords = async (
  brandUid: string
): Promise<VettingRecord[] | undefined> => {
  const response = await globalGetService(`mno/brands/${brandUid}/vettings`, {
    vettingStatus: 'ACTIVE',
  });
  return response?.ok ? response.data : undefined;
};

export const getCampaignsByBrand = async (
  brandUid: string,
  query: AdditionalFilters
): Promise<Pagination<CampaignsData> | undefined> => {
  const response = await globalGetService('mno/campaigns', {
    ...query,
    brandUid,
  });
  if (response?.ok) {
    const campaignInfo = response.data as Pagination<CampaignsData>;
    campaignInfo.records.forEach((campaign) => {
      campaign.createDate = convertTimeWithTimezone(
        campaign.createDate,
        undefined,
        US_DATE_FORMAT
      );
    });
    return campaignInfo;
  }
  return undefined;
};

export const getSuspensionRules = async (
  query = {}
): Promise<Pagination<SuspensionRule> | undefined> => {
  const response = await globalGetService('mno/suspenisonRule', { ...query });
  return response?.ok
    ? (response.data as Pagination<SuspensionRule>)
    : undefined;
};

const recursionGetCampaignUids = async (
  params: { brandUid: string; status: string; mnoStatus: string },
  page = 1,
  previousUids: string[] = []
): Promise<string[]> => {
  const response = await globalGetService('mno/campaigns', {
    ...params,
    page,
  });

  if (!response?.ok) {
    return previousUids;
  }

  const { records, recordsPerPage } =
    response.data as Pagination<CampaignsData>;
  const newUids = [...previousUids, ...records.map((campaign) => campaign.uid)];
  if (records.length < recordsPerPage) {
    return newUids;
  }

  return recursionGetCampaignUids(params, page + 1, newUids);
};

export const getAllCampaignUidsByBrand = async (
  brandUid: string,
  status: string,
  mnoStatus: string
) => {
  const uids = await recursionGetCampaignUids({
    brandUid,
    status,
    mnoStatus,
  });
  return uids;
};

export const updateCampaignsStatus = async (
  campaignUids: string[],
  status: string,
  explanation: string,
  suspensionCategory?: string
) => {
  const body = {
    campaignUid: campaignUids,
    status,
    explanation,
    suspensionCategory,
  };
  const response = await globalPutService(`mno/campaigns/status`, body);
  return response?.ok ? (response.data as Record<string, boolean>) : undefined;
};

export const getSuspensionRule = async (
  query = {}
): Promise<Pagination<SuspensionRule> | undefined> => {
  const response = await globalGetService('mno/suspenisonRule', { ...query });
  return response?.ok
    ? (response.data as Pagination<SuspensionRule>)
    : undefined;
};

export const getSuspensionCategoryOptions = async (): Promise<SelectItem[]> => {
  const response = await globalGetService('enums/suspensionCategories');
  if (response?.ok) {
    const categories = response.data as SuspensionCategory[];
    return categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }
  return [];
};
