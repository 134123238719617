import React, { FC, useEffect, useState } from 'react';
import {
  Backdrop,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { isEqual } from 'lodash-es';
import { Loader, FilterToggleButton } from '../../../../shared_elements';
import {
  CampaignListingRow,
  BrandDetailsBasic,
  BrandCspDetails,
  BrandAddressDetails,
  VettingDetailsListingRow,
  BrandDetailsSection,
  BrandActionModal,
  BrandContactDetails,
} from '../components';
import Spinner from '../../Campaigns/components/Spinner';
import {
  getAllUsecasesTypes,
  fetchCspSuggestions,
  getBrandDetail,
  getVettingRecords,
  getCampaignsByBrand,
  getSuspensionRules,
  getAllCampaignUidsByBrand,
  updateCampaignsStatus,
} from '../apis';
import { MNO_STATUS } from '../../../../constants';
import { toastFlashMessage } from '../../../../utils';
import { withFilters } from '../../../../hocs';
import '../assets/brand-details-module.scss';
import {
  Button,
  BoxV2 as Box,
  Flex,
  Table,
  FilterType,
  TableFilter,
  WithFiltersConfig,
  FilterConfig,
} from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faRotateRight,
  faSquareMinus,
  faBullhorn,
  faCircleUser,
  faFileMagnifyingGlass,
  faAddressBook,
} from '@fortawesome/pro-regular-svg-icons';
import {
  AdditionalFilters,
  DEFAULT_ADDITIONAL_FILTERS,
  Pagination,
  SelectItem,
} from '../../../../utils/types';
import { Brand, VettingRecord } from '../types';
import { CampaignsData } from '../../Campaigns/types';

const FILTER_CONFIG: Record<string, FilterConfig> = {
  usecase: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Use Case',
    placeholder: 'Use-Case',
  },
  cspName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Enter CSP Name',
    suggestions: [],
  },
  status: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    label: 'TCR Status',
    placeholder: 'TCR Status',
  },
  mnoStatus: {
    type: FilterType.Dropdown,
    options: MNO_STATUS,
    label: 'MNO Status',
    placeholder: 'MNO Status',
    multiple: true,
    width: 250,
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'status', label: 'TCR STATUS', sortable: false },
  { id: 'operationStatus', label: 'MNO STATUS', sortable: false },
];

const VettingDetailHeadRows = [
  { id: 'type', label: 'VETTING TYPE', sortable: false },
  { id: 'partner', label: 'VETTING PARTNER', sortable: false },
  { id: 'date', label: 'VETTED ON', sortable: false },
  { id: 'outcome', label: 'OUTCOME', sortable: false },
  { id: 'status', label: 'VETTING STATUS', sortable: false },
];

const fetchCspNameSuggestions = async (query = {}): Promise<string[]> => {
  const suggestions = await fetchCspSuggestions(query);
  return suggestions.map((suggestion) => suggestion.cspName);
};

const EmptyPaginationData: Pagination<CampaignsData> = {
  records: [],
  page: 1,
  recordsPerPage: 10,
  totalRecords: 0,
};

interface LocationState {
  brandInfo?: Brand;
  goBackPage?: string;
}

interface Props extends WithFiltersConfig {
  filter: {
    configs: Record<string, FilterConfig>;
    candidateFilters: Record<string, string>;
    appliedFilters: Record<string, string>;
    handleEdit: (value: Record<string, string>) => void;
    handleApply: (value: Record<string, string>) => void;
    setOptions: (field: string, options: SelectItem[]) => void;
  };
}

const BrandDetail: FC<Props> = ({ filter }) => {
  const { id: brandUid } = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [brandAction, setBrandAction] = useState('');
  const [campaignInfo, setCampaignInfo] =
    useState<Pagination<CampaignsData>>(EmptyPaginationData);
  const [brandInfo, setBrandInfo] = useState<Brand>();
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilters>(
    DEFAULT_ADDITIONAL_FILTERS
  );
  const [brandSuspended, setBrandSuspended] = useState<boolean | null>(null);
  const [goBackPage, setGoBackPage] = useState<string>('/brands');
  const [vettingRecords, setVettingRecords] = useState<VettingRecord[]>([]);
  const locationState = location.state as LocationState | undefined;

  const fetchFilterOptions = async () => {
    const { setOptions } = filter;
    const usecaseTypes = await getAllUsecasesTypes();

    setOptions(
      'usecase',
      usecaseTypes.map((item) => ({
        label: item.displayName,
        value: item.id,
      }))
    );
  };

  const updateMnoStatusReviewOptionDisabled = (disabled = false) => {
    const { setOptions } = filter;
    setOptions(
      'mnoStatus',
      MNO_STATUS.map((item) =>
        item.value === 'REVIEW' ? { ...item, disabled } : item
      )
    );
  };

  const parseSearchParams = (params = ''): AdditionalFilters => {
    let updatedFilter = { ...additionalFilters };
    const { appliedFilters } = filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      updatedFilter = DEFAULT_ADDITIONAL_FILTERS;
    } else if (queries.sortField || queries.ascendingOrder) {
      updatedFilter = {
        sortField: queries.sortField as string,
        ascendingOrder: JSON.parse(queries.ascendingOrder as string),
      };
    }

    setAdditionalFilters(updatedFilter);

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: (queries?.page as string) ?? 1,
    };
  };

  const processFilterSpecialCase = (
    values: AdditionalFilters | Record<string, string>
  ): AdditionalFilters => {
    const updatedFilters = { ...values };
    const { configs } = filter;
    const isStatusExpired = values?.status === 'EXPIRED';
    const isMnoStatusHasPreview = (values?.mnoStatus as string)?.includes(
      'REVIEW'
    );
    const mnoStatusReviewIsDisabled = configs.mnoStatus.options?.find(
      (item) => item.value === 'REVIEW' && item?.disabled
    );

    if (isStatusExpired) {
      if (isMnoStatusHasPreview) {
        const newMnoStatus = (values.mnoStatus as string)
          .split(',')
          .filter((item) => item !== 'REVIEW')
          .join(',');
        if (newMnoStatus) {
          updatedFilters.mnoStatus = newMnoStatus;
        } else {
          delete updatedFilters.mnoStatus;
        }
      }
      if (!mnoStatusReviewIsDisabled) {
        updateMnoStatusReviewOptionDisabled(true);
      }
    } else if (mnoStatusReviewIsDisabled) {
      updateMnoStatusReviewOptionDisabled(false);
    }
    return updatedFilters as AdditionalFilters;
  };

  const fetchBrandDetail = async (uid: string) => {
    const data = await getBrandDetail(uid);
    if (data) {
      setBrandInfo(data);
    }
    setLoader(false);
  };

  const fetchVettingRecords = async (uid: string) => {
    const records = await getVettingRecords(uid);
    if (records) {
      setVettingRecords(records);
    }
  };

  useEffect(() => {
    if (locationState?.goBackPage) {
      setGoBackPage(locationState.goBackPage);
    }
    if (locationState?.brandInfo) {
      setBrandInfo(locationState.brandInfo);
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    if (!locationState?.brandInfo) {
      fetchBrandDetail(brandUid);
    }
    fetchVettingRecords(brandUid);
  }, [brandUid, locationState?.brandInfo]);

  useEffect(() => {
    if (location.search) {
      const params = parseSearchParams(location.search);
      const finalQuery = processFilterSpecialCase(params);
      if (isEqual(params, finalQuery)) {
        fetchCampaignsByBrand(finalQuery);
      } else {
        writeQueryString(finalQuery);
      }
    } else {
      fetchCampaignsByBrand({ ...additionalFilters });
    }
  }, [location.search]);

  useEffect(() => {
    fetchFilterOptions();
    checkSuspensionStatus();
  }, []);

  const fetchCampaignsByBrand = async (query: AdditionalFilters) => {
    setTableLoader(true);
    const updatedQuery = { ...query };
    const isStatusExpired = query?.status === 'EXPIRED';
    if (isStatusExpired && !query?.mnoStatus) {
      updatedQuery.mnoStatus = 'REJECTED,REGISTERED,SUSPENDED';
    }
    if (
      updatedQuery?.status &&
      (updatedQuery.status as string).split(',').length > 1
    ) {
      delete updatedQuery.status;
    }
    const campaignData = await getCampaignsByBrand(brandUid, updatedQuery);
    if (campaignData) {
      setCampaignInfo(campaignData);
      if (
        campaignData.records.length === 0 &&
        campaignData.totalRecords > 0 &&
        campaignData.page > 1
      ) {
        const lastPageNo = Math.ceil(campaignData.totalRecords / 10);
        writeQueryString({ ...query, page: lastPageNo });
        return;
      }
    }
    setTableLoader(false);
  };

  const handleCandidateFiltersChange = (values: Record<string, string>) => {
    const newValues = processFilterSpecialCase(values);
    filter.handleEdit(newValues as Record<string, string>);
  };

  const checkSuspensionStatus = async () => {
    const rules = await getSuspensionRules({ brandUid, exactMatch: true });
    if (rules && rules.records.length > 0) {
      const found = rules.records.some(
        (rule) => rule.brandUid === brandUid && rule.status === 'ACTIVE'
      );
      setBrandSuspended(found);
    } else {
      setBrandSuspended(false);
    }
  };

  const handleChangePage = (newPage: number) => {
    const { appliedFilters } = filter;

    writeQueryString({
      ...appliedFilters,
      ...additionalFilters,
      page: newPage,
    });
  };

  const handleSorting = (sortField: string, ascendingOrder: boolean) => {
    const { appliedFilters } = filter;
    const updatedAdditionalFilters = {
      ...additionalFilters,
      ascendingOrder,
      sortField,
    };
    setAdditionalFilters(updatedAdditionalFilters);

    if (campaignInfo.totalRecords) {
      writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: campaignInfo.page,
        sortField,
        ascendingOrder,
      });
    }
  };

  const handleGoBack = () => {
    history.push(`${goBackPage}`);
  };

  const writeQueryString = (searchParams: AdditionalFilters) => {
    history.push({
      search: `?${queryString.stringify(searchParams, { encode: true })}`,
    });
  };

  const bulkUpdateCampaignsStatus = async (
    uids: string[],
    status: 'SUSPENDED' | 'REGISTERED',
    explanation: string,
    suspensionCategory?: string,
    totalUids?: number
  ): Promise<boolean> => {
    const total = totalUids || uids.length;
    const batchUids = uids.splice(0, 10);
    const inProcess = total - uids.length;
    const actionText = status === 'SUSPENDED' ? 'Suspending' : 'Lifting';
    setModalMessage(`${actionText} ${inProcess}/${total} campaigns...`);

    await updateCampaignsStatus(
      batchUids,
      status,
      explanation,
      suspensionCategory
    );

    if (uids.length > 0) {
      return bulkUpdateCampaignsStatus(
        uids,
        status,
        explanation,
        suspensionCategory,
        total
      );
    }
    return true;
  };

  const suspendAllCampaigns = async (
    explanation: string,
    suspensionCategory: string
  ) => {
    setModalOpen(true);
    setModalMessage('Suspending all campaigns...');
    const campaignUids = await getAllCampaignUidsByBrand(
      brandUid,
      'ACTIVE',
      'REGISTERED'
    );

    await bulkUpdateCampaignsStatus(
      campaignUids,
      'SUSPENDED',
      explanation,
      suspensionCategory
    );

    setModalOpen(false);
    setModalMessage('');
    fetchCampaignsByBrand({ ...additionalFilters });
    toastFlashMessage('Successfully updated campaign status', 'success');
  };

  const liftAllSuspensions = async (explanation: string) => {
    setModalOpen(true);
    setModalMessage('Lift all suspensions...');
    const campaignUids = await getAllCampaignUidsByBrand(
      brandUid,
      'ACTIVE',
      'SUSPENDED'
    );

    await bulkUpdateCampaignsStatus(campaignUids, 'REGISTERED', explanation);

    setModalOpen(false);
    setModalMessage('');
    fetchCampaignsByBrand({ ...additionalFilters });
    toastFlashMessage('Successfully updated campaign status', 'success');
  };

  const goToBrandSuspension = () => {
    history.push({
      pathname: `/brand-suspension/${brandUid}`,
      state: { brandSuspended },
    });
  };

  return (
    <section className="brand-details-section page-content">
      {loader || !brandInfo ? (
        <Loader />
      ) : (
        <Container maxWidth={false} className="brand-details-container">
          <Grid container className="top-blk" style={{ height: '40px' }}>
            <Grid item xs={6} className="back-btn">
              <a onClick={handleGoBack} className="paragraph">
                <Flex sx={{ alignItems: 'center' }}>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    size="lg"
                    style={{ marginRight: '12px' }}
                  />
                  {goBackPage === '/brands' ? 'back to all brands' : 'back'}
                </Flex>
              </a>
            </Grid>
            <Grid item xs={6} className="button-wrapper flex-centered">
              {brandSuspended === null ? (
                <CircularProgress
                  size={40}
                  style={{ color: '#00698F', marginRight: '50px' }}
                />
              ) : brandSuspended ? (
                <React.Fragment>
                  <Button
                    color="secondary"
                    data-testid="gotoLiftBrandSuspensionButton"
                    onClick={goToBrandSuspension}
                  >
                    <FontAwesomeIcon icon={faRotateRight} />
                    <span>Lift Brand Suspension</span>
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    data-testid="gotoBrandSuspensionButton"
                    onClick={goToBrandSuspension}
                  >
                    <Box>
                      <FontAwesomeIcon icon={faSquareMinus} size="lg" />
                    </Box>
                    <span>Suspend Brand</span>
                  </Button>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <Grid container className="brand-details basic">
            <Grid item xs={12}>
              <div className="title" data-testid="brandDetailDisplayName">
                <h3 className="heading1">
                  Brand Name:{' '}
                  {brandInfo.entityType.toLowerCase() === 'sole_proprietor'
                    ? 'Redacted due to PII'
                    : brandInfo.displayName
                    ? brandInfo.displayName
                    : 'N/A'}
                </h3>
                {brandInfo.entityType.toLowerCase() !== 'sole_proprietor' && (
                  <p className="heading2">
                    Legal Company Name:{' '}
                    {brandInfo.companyName ? brandInfo.companyName : 'N/A'}
                  </p>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <BrandDetailsBasic
                brandInfo={brandInfo}
                brandSuspended={brandSuspended}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className="contact details">
                <div className="details-heading">
                  <Flex sx={{ alignItems: 'center' }}>
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      style={{ fontSize: '18px', marginRight: 8 }}
                    />
                    <h3>CSP Details</h3>
                  </Flex>
                </div>
                <BrandCspDetails cspInfo={brandInfo.csp} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="contact details">
                <div className="details-heading">
                  <Flex sx={{ alignItems: 'center' }}>
                    <FontAwesomeIcon
                      icon={faAddressBook}
                      style={{ fontSize: '18px', marginRight: 8 }}
                    />
                    <h3>Brand Details</h3>
                  </Flex>
                </div>
                <BrandDetailsSection brand={brandInfo as Brand} />
                <div className="details-heading" style={{ marginTop: '30px' }}>
                  <Flex sx={{ alignItems: 'center' }}>
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      style={{ fontSize: '18px', marginRight: 8 }}
                    />
                    <h3>Brand Address Details</h3>
                  </Flex>
                </div>
                <BrandAddressDetails
                  street={brandInfo.street}
                  city={brandInfo.city}
                  state={brandInfo.state}
                  postalCode={brandInfo.postalCode}
                  country={brandInfo.country}
                />
                {brandInfo?.entityType === 'PUBLIC_PROFIT' && (
                  <>
                    <Box className="details-heading" mt="xxl">
                      <Flex sx={{ alignItems: 'center' }}>
                        <Box mr="xs">
                          <FontAwesomeIcon icon={faAddressBook} size="xl" />
                        </Box>
                        <h3>Business Contact Details</h3>
                      </Flex>
                    </Box>
                    <BrandContactDetails data={brandInfo} />
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="vetting details">
                <div className="details-heading">
                  <Flex sx={{ alignItems: 'center' }}>
                    <Box mr="xs">
                      <FontAwesomeIcon icon={faFileMagnifyingGlass} size="xl" />
                    </Box>
                    <h3>Vetting Details</h3>
                  </Flex>
                </div>
                <Table
                  data-testid="vettingListingTable"
                  className="vetting-listing-table"
                  loading={false}
                  headRows={VettingDetailHeadRows}
                  emptyState="no vettings to view"
                  disableHover={true}
                  tableData={vettingRecords.map((record, index) => (
                    <VettingDetailsListingRow key={index} record={record} />
                  ))}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="campaigns-listing details">
                <Flex>
                  <div className="w-100">
                    <Flex sx={{ alignItems: 'center' }}>
                      <Box mr="xs">
                        <FontAwesomeIcon icon={faBullhorn} size="xl" />
                      </Box>
                      <h3>{`${campaignInfo.totalRecords} Campaigns`}</h3>
                    </Flex>
                  </div>
                </Flex>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    mt: 'xs',
                    mb: 's',
                  }}
                >
                  <Flex sx={{ justifyContent: 'space-between' }}>
                    <FilterToggleButton
                      visible={isFilterVisible}
                      onClick={() => setIsFilterVisible(!isFilterVisible)}
                      size="small"
                    />
                    <Flex sx={{ gap: 's' }}>
                      <Button
                        data-testid="suspendAllCampaignButton"
                        portal="mno"
                        shape="rounded"
                        variant="standard"
                        color="primary"
                        size="small"
                        disabled={!campaignInfo.totalRecords}
                        onClick={(event) => {
                          // If we do not add stop Propagation and prevent default. It would close the modal.
                          event.stopPropagation();
                          event.preventDefault();
                          setBrandAction('suspend');
                        }}
                      >
                        <Box>
                          <FontAwesomeIcon icon={faSquareMinus} size="lg" />
                        </Box>
                        <span>Suspend All Campaigns</span>
                      </Button>
                      <Button
                        data-testid="liftAllSuspensionButton"
                        portal="mno"
                        shape="rounded"
                        variant="standard"
                        color="secondary"
                        size="small"
                        disabled={!campaignInfo.totalRecords}
                        onClick={(event) => {
                          // If we do not add stop Propagation and prevent default. It would close the modal.
                          event.stopPropagation();
                          event.preventDefault();
                          setBrandAction('lift');
                        }}
                      >
                        <FontAwesomeIcon icon={faRotateRight} color="#FFFFFF" />
                        <span>Lift All Campaign Suspensions</span>
                      </Button>
                    </Flex>
                  </Flex>
                  {isFilterVisible && (
                    <TableFilter
                      portal="mno"
                      configs={filter.configs}
                      candidateValues={filter.candidateFilters}
                      appliedValues={filter.appliedFilters}
                      onCandidateValuesChange={(
                        values: Record<string, string>
                      ) => handleCandidateFiltersChange(values)}
                      onAppliedValuesChange={filter.handleApply}
                      className="filters"
                      data-testid="brandDetailCampaigns"
                    />
                  )}
                </Flex>
                <Table
                  data-testid="campaignListingTable"
                  loading={tableLoader}
                  emptyState="no campaigns to view"
                  handleChangePage={handleChangePage}
                  createSortHandler={handleSorting}
                  filter={additionalFilters}
                  headRows={headRows}
                  data={campaignInfo.records.map((record) => ({ ...record }))}
                  rowKey="uid"
                  renderRow={(data) => {
                    return (
                      <CampaignListingRow
                        key={data?.uid as string}
                        campaign={data as unknown as CampaignsData}
                      />
                    );
                  }}
                  pagination={{
                    page: campaignInfo.page,
                    rowsPerPage: campaignInfo.recordsPerPage,
                    totalRecords: campaignInfo.totalRecords,
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Backdrop
            style={{
              background: 'rgba(14, 31, 43, 0.75)',
              zIndex: 1005,
            }}
            open={modalOpen}
          >
            <Container className="modal">
              <Spinner />
              <Typography
                variant="body1"
                align="center"
                style={{
                  width: '100%',
                  marginTop: 40,
                  color: '#49535D',
                  wordBreak: 'break-all',
                }}
              >
                {modalMessage}
              </Typography>
            </Container>
          </Backdrop>
        </Container>
      )}
      <BrandActionModal
        open={!!brandAction}
        type={brandAction}
        cspUid={brandInfo?.cspUid ?? ''}
        brandName={
          brandInfo?.displayName
            ? brandInfo.displayName
            : brandInfo?.entityType === 'SOLE_PROPRIETOR'
            ? 'Redacted due to PII'
            : 'N/A'
        }
        onCancel={() => setBrandAction('')}
        onSubmit={(explanation, suspensionCategory) => {
          if (brandAction === 'suspend') {
            suspendAllCampaigns(explanation, suspensionCategory);
          } else {
            liftAllSuspensions(explanation);
          }
          setBrandAction('');
        }}
      />
    </section>
  );
};

export default withFilters(BrandDetail, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      cspName: {
        minLength: 2,
        load: (value) => fetchCspNameSuggestions({ prefix: value }),
      },
    },
  },
});
